export const meta: BlockMeta = {
  editMode: "both",
  title: "Subscribe",
  description: "",
  keywords: ["subscribe"],
  // @ts-ignore
  icon: "buddicons-pm",
  cache: false,
  tags: ["root"],
}

import { defineBlock, EditableText } from "eddev/blocks"
import { GridLines } from "@components/atoms/GridLines"
import { useAdjustedHeight } from "@hooks/useAdjustedHeight"
import { useForm } from "@hooks/queries"
import { GravityForm } from "@components/forms/GravityForm"

export default defineBlock("content/subscribe", (props) => {
  const { addRef } = useAdjustedHeight()
  const form = useForm({ id: "1" })

  return (
    <div
      ref={(el) => (props.gridlines ? el && addRef(el) : undefined)}
      className={`subtheme-${props.scheme} relative subscribe-form`}
    >
      {props.gridlines && <GridLines />}
      <div className="w-full h-full flex flex-col items-center justify-center bg-bg py-[80px]">
        <div className="grid-auto relative items-center lg:max-w-[1280px] xl:max-w-[1440px]">
          <div className="col-span-12 md:col-span-6 lg:col-span-4 md:col-start-4 lg:col-start-5 flex flex-col justify-center items-center gap-8">
            <EditableText
              store="title"
              as="h2"
              placeholder={"Enter title..."}
              className="type-title-l text-text w-full text-center"
            />

            {form.data?.gravityForm?.form && <GravityForm form={form.data.gravityForm.form} />}

            <EditableText
              store="content"
              as="p"
              placeholder={"Enter content..."}
              className="type-body-m text-text w-full text-center"
            />
          </div>
        </div>
      </div>
    </div>
  )
})
